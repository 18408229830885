import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import { ImagePath, TopImage } from './types/TopImageTypes';
import { useState, createContext, lazy, Suspense } from 'react';
import MainPageSkeleton from './components/share/MainPageSkeleton/MainPageSkeleton';
import Home from './components/pages/Home/Home';
import Shops from './components/pages/Shops/Shops';
import ShopSearch from './components/pages/ShopSearch/ShopSearch';
import NotFound from './components/pages/NotFound/NotFound';
import NewsSkeleton from './components/pages/News/NewsSkeleton';
import BusinessCategorySkeleton from './components/pages/BusinessCategory/BusinessCategorySkeleton';
import ShopSkeleton from './components/pages/Shop/ShopSkeleton';

const About = lazy(() => import('./components/pages/About/About'));
const Career = lazy(() => import('./components/pages/Career/Career'))
const Contacts = lazy(() => import('./components/pages/Contacts/Contacts'))
const News = lazy(() => import('./components/pages/News/News'))
const BusinessCategory = lazy(() => import('./components/pages/BusinessCategory/BusinessCategory'))
const SubCategory = lazy(() => import('./components/pages/SubCategory/SubCategory'))
const SingleNews = lazy(() => import('./components/pages/SingleNews/SingleNews'))
const CGCh = lazy(() => import('./components/pages/CGCh/CGCh'));
const Land = lazy(() => import('./components/pages/Land/Land'));
const Shop = lazy(() => import('./components/pages/Shop/Shop'));

export const TopImageContext = createContext<TopImage>({ image: { src: '', local: false }, setImage: () => { } })

const App: React.FC = () => {
  const [topImage, setTopImage] = useState<ImagePath>({ src: '', local: false })

  return <TopImageContext.Provider value={{ image: topImage, setImage: setTopImage }}>
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='about-us' element={<Suspense fallback={<MainPageSkeleton />}><About /></Suspense>} />
        <Route path='news' element={<Suspense fallback={<NewsSkeleton />}><News /></Suspense>} />
        <Route path='news/:article' element={<Suspense fallback={<MainPageSkeleton />}><SingleNews /></Suspense>} />
        <Route path='careers' element={<Suspense fallback={<MainPageSkeleton />}><Career /></Suspense>} />
        <Route path='contacts' element={<Suspense fallback={<MainPageSkeleton />}><Contacts /></Suspense>} />
        <Route path='cat/:article' element={<Suspense fallback={<BusinessCategorySkeleton />}><BusinessCategory /></Suspense>} />
        <Route path='cat/shops' element={<Shops />} />
        <Route path='cat/shops/:shop' element={<Suspense fallback={<ShopSkeleton />}><Shop /></Suspense>} />
        <Route path='cat/shops/search' element={<ShopSearch />} />
        <Route path='view/:article' element={<Suspense fallback={<BusinessCategorySkeleton />}><SubCategory /></Suspense>} />
        <Route path='view/centr-galerei-cizova' element={<Suspense fallback={<BusinessCategorySkeleton />}><CGCh slug="centr-galerei-cizova" /></Suspense>} />
        <Route path='view/arenda-v-torgovom-centre' element={<Suspense fallback={<BusinessCategorySkeleton />}><CGCh slug="arenda-v-torgovom-centre" /></Suspense>} />
        <Route path='view/lease' element={<Suspense fallback={<BusinessCategorySkeleton />}><CGCh slug="lease" /></Suspense>} />
        <Route path='view/land' element={<Suspense fallback={<BusinessCategorySkeleton />}><Land /></Suspense>} />
        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>
  </TopImageContext.Provider>
}

export default App;
