import { classNames } from '../../../lib/classNames/classNames';
import style from './SkeletonPlaceholder.module.scss'

interface SkeletonPlaceholderProps {
  className?: string,
}

const SkeletonPlaceholder: React.FC<SkeletonPlaceholderProps> = ({ className }) => {
  return <div className={classNames(style.wrapper, {}, [className ?? ''])}>
    <div className={style.activity} />
  </div>
}

export default SkeletonPlaceholder;