import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";
import MobileMenu from "./MobileMenu/MobileMenu";
import Header from "./Header/Header";
import style from './Layout.module.scss'

const Layout: React.FC = () => {
  return (
    <>
      {window.matchMedia('(max-width:992px)').matches && <MobileMenu />}
      <Header />
      <main className={style.main}>
        <Outlet />
      </main>
      <Footer />
    </>
  )
}

export default Layout;