import style from './Search.module.scss'
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import searchIcon from '../../../assets/image/search.svg'

const Search: React.FC = () => {
  const [searchParams] = useSearchParams();
  const initialValue = searchParams.get('search');
  const [value, setValue] = useState<string>(initialValue ?? '');
  const navigate = useNavigate();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (value) {
      navigate(`/cat/shops/search?search=${value}`);
    }
  }

  return <form className={style.searchForm} onSubmit={onSubmit}>
    <input type="text"
      placeholder='Найти'
      value={value}
      onChange={e => setValue(e.target.value)}
      className={style.searchInput} />
    <button type='submit'
      aria-label='Поиск'
      className={style.searchBtn}>
      <img src={searchIcon}
        alt="Поиск"
        className={style.searchIcon}
      />
    </button>
  </form>
}

export default Search;