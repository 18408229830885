import SkeletonPlaceholder from '../../../share/SkeletonPlaceholder/SkeletonPlaceholder';
import style from './CategoryBlock.module.scss'

const CategoryBlockSkeleton: React.FC = () => {
  return <div className={style.grid}>
    {new Array(12).fill(null).map((item, index) => <div key={index}>
      <SkeletonPlaceholder className={style.titlePlaceholder} />
      <SkeletonPlaceholder className={style.itemImageBlock} />
    </div>)}
  </div>
}

export default CategoryBlockSkeleton;