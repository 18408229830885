import style from './ShopSearch.module.scss'
import { useContext, useEffect, useState } from 'react'
import topImg from '../../../assets/image/slider/6.webp'
import topImgMobile from '../../../assets/image/slider/tel6.webp'
import ShopsContainer from '../Shops/ShopsContainer/ShopsContainer'
import { useSearchParams } from 'react-router-dom'
import { setDocumentTitle } from '../../../lib/setDocumentTitle/setDocumentTitle'
import { TopImageContext } from '../../../App'
import { useQuery } from 'react-query'
import { getData } from '../../../lib/services/services'
import Container from '../../UI/Container/Container'
import Search from '../../share/Search/Search'
import Pagination from '../../share/Pagination/Pagination'
import { ApiDataResponseType, IShop } from '../../../types/apiDataTypes'

const isPhone = window.matchMedia("(max-width: 768px)").matches;

const ShopSearch: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get('page')) || 1;
  const searchString = searchParams.get('search')
  const { setImage } = useContext(TopImageContext);

  const { data: searchResult, isLoading } = useQuery({
    queryKey: ['searchResult', searchString, page],
    queryFn: (): Promise<ApiDataResponseType<IShop[]>> => getData(`shop/search?search=${searchString}&page=${page}`, false)
  })

  const changePage = (page: number) => {
    if (page === 1) searchParams.delete('page');
    else searchParams.set('page', String(page));
    setSearchParams(searchParams)
  }

  useEffect(() => {
    setDocumentTitle('Результат поиска')
    setImage({ src: isPhone ? topImgMobile : topImg, local: true })
  }, [setImage])

  return <Container className={style.wrapper}>
    <h1 className={style.title}>Результат поиска</h1>
    <Search />
    {
      isLoading ?
        <p className={style.searchLoading}>Идет поиск...</p> :
        searchResult?.models?.length
          ? <div className={style.shops}>
            <ShopsContainer shops={searchResult.models} />
          </div>
          : <p className={style.result}>
            По запросу {searchString} ничего не найдено
          </p>
    }
    {
      Number(searchResult?.count) > 1 && <Pagination activePage={page}
        setActivePage={changePage}
        numberOfPages={Number(searchResult?.count)} />
    }
  </Container>
}

export default ShopSearch;