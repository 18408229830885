import SkeletonPlaceholder from '../../../share/SkeletonPlaceholder/SkeletonPlaceholder';
import style from './NewsItem.module.scss'

const NewsItemPlaceholder: React.FC = () => {
  return <div className={style.wrapper}>
    <SkeletonPlaceholder className={style.imgWrapper} />
    <div className={style.body}>
      <SkeletonPlaceholder className={style.titlePlaceholder} />
      <SkeletonPlaceholder className={style.shortPlaceholder} />
    </div>
  </div>
}

export default NewsItemPlaceholder;