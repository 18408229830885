import style from './HeaderSlider.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import "swiper/css";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import slide1 from '../../../../assets/image/slider/1.webp';
import slide2 from '../../../../assets/image/slider/2.webp';
import slide3 from '../../../../assets/image/slider/3.webp';
import slide4 from '../../../../assets/image/slider/4.webp';
import slide5 from '../../../../assets/image/slider/5.webp';
import slide6 from '../../../../assets/image/slider/6.webp';
import blur1 from '../../../../assets/image/slider/blur1.jpg';
import blur2 from '../../../../assets/image/slider/blur2.jpg';
import blur3 from '../../../../assets/image/slider/blur3.jpg';
import blur4 from '../../../../assets/image/slider/blur4.jpg';
import blur5 from '../../../../assets/image/slider/blur5.jpg';
import blur6 from '../../../../assets/image/slider/blur6.jpg';
import slideMob1 from '../../../../assets/image/slider/tel1.webp';
import slideMob2 from '../../../../assets/image/slider/tel2.webp';
import slideMob3 from '../../../../assets/image/slider/tel3.webp';
import slideMob4 from '../../../../assets/image/slider/tel4.webp';
import slideMob5 from '../../../../assets/image/slider/tel5.webp';
import slideMob6 from '../../../../assets/image/slider/tel6.webp';
import blurMob1 from '../../../../assets/image/slider/blurTel1.jpg';
import blurMob2 from '../../../../assets/image/slider/blurTel2.jpg';
import blurMob3 from '../../../../assets/image/slider/blurTel3.jpg';
import blurMob4 from '../../../../assets/image/slider/blurTel4.jpg';
import blurMob5 from '../../../../assets/image/slider/blurTel5.jpg';
import blurMob6 from '../../../../assets/image/slider/blurTel6.jpg';

const isPhone = window.matchMedia("(max-width: 768px)").matches;

const HeaderSlider: React.FC = () => {
  return <Swiper
    loop={true}
    className={style.slider}
    speed={700}
    autoplay={{
      delay: 10000,
      disableOnInteraction: false,
    }}
    modules={[Autoplay]}
  >
    <SwiperSlide className={style.item}>
      {isPhone ? <LazyLoadImage src={slideMob1}
        width="100%"
        height="100%"
        alt="слайд 1"
        placeholderSrc={blurMob1}
        effect='blur'
      /> :
        <LazyLoadImage src={slide1}
          width="100%"
          height="100%"
          alt="слайд 1"
          placeholderSrc={blur1}
          effect='blur'
        />}
    </SwiperSlide>
    <SwiperSlide className={style.item}>
      {isPhone ? <LazyLoadImage src={slideMob2}
        width="100%"
        height="100%"
        alt="слайд 2"
        placeholderSrc={blurMob2}
        effect='blur'
      /> :
        <LazyLoadImage src={slide2}
          width="100%"
          height="100%"
          alt="слайд 2"
          placeholderSrc={blur2}
          effect='blur'
        />}
    </SwiperSlide>
    <SwiperSlide className={style.item}>
      {isPhone ? <LazyLoadImage src={slideMob3}
        width="100%"
        height="100%"
        alt="слайд 3"
        placeholderSrc={blurMob3}
        effect='blur'
      /> :
        <LazyLoadImage src={slide3}
          width="100%"
          height="100%"
          alt="слайд 3"
          placeholderSrc={blur3}
          effect='blur'
        />}
    </SwiperSlide>
    <SwiperSlide className={style.item}>
      {isPhone ? <LazyLoadImage src={slideMob4}
        width="100%"
        height="100%"
        alt="слайд 4"
        placeholderSrc={blurMob4}
        effect='blur'
      /> :
        <LazyLoadImage src={slide4}
          width="100%"
          height="100%"
          alt="слайд 4"
          placeholderSrc={blur4}
          effect='blur'
        />}
    </SwiperSlide>
    <SwiperSlide className={style.item}>
      {isPhone ? <LazyLoadImage src={slideMob5}
        width="100%"
        height="100%"
        alt="слайд 5"
        placeholderSrc={blurMob5}
        effect='blur'
      /> :
        <LazyLoadImage src={slide5}
          width="100%"
          height="100%"
          alt="слайд 5"
          placeholderSrc={blur5}
          effect='blur'
        />}
    </SwiperSlide>
    <SwiperSlide className={style.item}>
      {isPhone ? <LazyLoadImage src={slideMob6}
        width="100%"
        height="100%"
        alt="слайд 6"
        placeholderSrc={blurMob6}
        effect='blur'
      /> :
        <LazyLoadImage src={slide6}
          width="100%"
          height="100%"
          alt="слайд 6"
          placeholderSrc={blur6}
          effect='blur'
        />}
    </SwiperSlide>
  </Swiper>
}

export default HeaderSlider;