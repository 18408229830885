import { useState } from 'react'
import style from './MobileMenu.module.scss'
import { Link } from 'react-router-dom'
import logo from '../../../assets/image/menu-logo_mobile.png'
import { scrollToTop } from '../../../lib/scroll/scroll'
import { IBusinessCategory } from '../../../types/apiDataTypes'
import { useQuery } from 'react-query'
import { getData } from '../../../lib/services/services'
import Portal from '../../share/Portal/Portal'
import SkeletonPlaceholder from '../../share/SkeletonPlaceholder/SkeletonPlaceholder'
import { classNames } from '../../../lib/classNames/classNames'

const MobileMenu: React.FC = () => {
  const [burger, setBurger] = useState(false);
  let { data: categories, isLoading } = useQuery({
    queryKey: ['categories'],
    queryFn: (): Promise<IBusinessCategory[]> => getData('business-category')
  })

  const openBurger = () => {
    setBurger(true);
  }

  const closeBurger = () => {
    setBurger(false);
  }

  const handleNavigation = () => {
    closeBurger();
    scrollToTop()
  }

  return <Portal>
    <button aria-label='Открыть меню' className={style.openBtn} type="button" tabIndex={0} onClick={openBurger}>
      <svg className={style.openBtnSvg} viewBox="0 0 24 24" aria-hidden="true">
        <path d="M3 18h13v-2H3v2zm0-5h10v-2H3v2zm0-7v2h13V6H3zm18 9.59L17.42 12 21 8.41 19.59 7l-5 5 5 5L21 15.59z"></path>
      </svg>
    </button>
    <div className={classNames(style.overlay, { [style.active]: burger })} onClick={closeBurger}>
      <div className={style.wrapper} tabIndex={0} onClick={e => e.stopPropagation()}>
        <div className={style.logo} onClick={handleNavigation}>
          <Link to="/" tabIndex={0}>
            <img src={logo} alt="Логотип" width='100px' height='75px' />
          </Link>
        </div>
        <button className={style.closeBtn} type="button" aria-label="Закрыть меню" onClick={closeBurger} tabIndex={0}>
          <svg viewBox="0 0 24 24" aria-hidden="true">
            <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"></path>
          </svg>
        </button>
        <ul className={style.linksList}>
          <li className={style.listItem} onClick={handleNavigation}>
            <Link tabIndex={0} to="about-us">О нас</Link>
          </li>
          <li className={style.listItem} onClick={handleNavigation}>
            <Link tabIndex={0} to="news">Новости</Link>
          </li>
          <li className={style.listItem} onClick={handleNavigation}>
            <Link tabIndex={0} to="careers">Карьера</Link>
          </li>
          <li className={style.listItem} onClick={handleNavigation}>
            <Link tabIndex={0} to="contacts">Контакты</Link>
          </li>
        </ul>
        <hr className={style.divider} />
        <ul className={style.linksList}>
          {
            isLoading ?
              new Array(13).fill(null).map((item, index) => <li className={style.listItem} key={index}>
                <SkeletonPlaceholder className={style.categoryPlaceholder} />
              </li>)
              : categories?.length ?
                categories.map(item => {
                  return <li key={item.slug} className={style.listItem}>
                    <Link tabIndex={0} to={`/cat/${item.slug}`} onClick={handleNavigation}>
                      {item.title}
                    </Link>
                  </li>
                })
                : null
          }
        </ul>
      </div>
    </div>
  </Portal>
}

export default MobileMenu;