import MainPageSkeleton from '../../share/MainPageSkeleton/MainPageSkeleton';
import SkeletonPlaceholder from '../../share/SkeletonPlaceholder/SkeletonPlaceholder';
import style from './BusinessCategory.module.scss'

const BusinessCategorySkeleton: React.FC = () => {
  return <div className={style.wrapper}>
    <MainPageSkeleton />
    <SkeletonPlaceholder className={style.bottomImgBlock} />
  </div>
}

export default BusinessCategorySkeleton;