export const BASE_URL = 'https://chg-mall.ru/';

export const sendForm = async<DataType>(data: DataType, token: string, path: string) => {
  let formData = new FormData();
  for (let item in data) {
    formData.append(item, String(data[item]))
  }
  formData.set('reCaptcha', token);
  const status = await fetch(`${BASE_URL}api/agch/${path}/create`, {
    body: formData,
    method: 'post'
  })
  return status;
}

export const getData = async<DataType extends {}>(path: string, models: boolean = true): Promise<DataType> => {
  const result = await fetch(`${BASE_URL}api/agch/${path}`)
    .then(res => res.json())
  return models ? result.models : result;
}