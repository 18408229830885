import { classNames } from '../../../../lib/classNames/classNames';
import style from './HeaderNavigation.module.scss'
import { NavLink } from 'react-router-dom';

const HeaderNavigation: React.FC = () => {
  return (
    <ul className={style.navigation}>
      <li><NavLink to='about-us' className={({ isActive }) => classNames(style.link, {[style.activeLink]: isActive})}>О НАС</NavLink></li>
      <li><NavLink to="news" className={({ isActive }) => classNames(style.link, {[style.activeLink]: isActive})}>ПРЕСС-СЛУЖБА</NavLink></li>
      <li><NavLink to="careers" className={({ isActive }) => classNames(style.link, {[style.activeLink]: isActive})}>КАРЬЕРА</NavLink></li>
      <li><NavLink to="contacts" className={({ isActive }) => classNames(style.link, {[style.activeLink]: isActive})}>КОНТАКТЫ</NavLink></li>
    </ul>
  )
}

export default HeaderNavigation;