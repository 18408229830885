import Container from '../../UI/Container/Container';
import SkeletonPlaceholder from '../../share/SkeletonPlaceholder/SkeletonPlaceholder';
import style from './Shop.module.scss'

const ShopSkeleton: React.FC = () => {
  return <Container className={style.wrapper}>
    <div className={style.linkBack}>
      <SkeletonPlaceholder className={style.linkBackPlaceholder} />
    </div>
    <SkeletonPlaceholder className={style.logoWrapper} />
    <div className={style.info}>
      <SkeletonPlaceholder className={style.infoPlaceholder} />
    </div>
    <div className={style.workTimeWrapper}>
      <SkeletonPlaceholder className={style.workTimePlaceholder} />
    </div>
    <div className={style.description}>
      <SkeletonPlaceholder className={style.descriptionPlaceholder} />
    </div>
  </Container>
}

export default ShopSkeleton;