import style from './Header.module.scss'
import logo from '../../../assets/image/logotype.png'
import HeaderNavigation from './HeaderNavigation/HeaderNavigation';
import { Link } from 'react-router-dom';
import HeaderSlider from './HeaderSlider/HeaderSlider';
import { BASE_URL } from '../../../lib/services/services';
import { useContext } from 'react';
import { TopImageContext } from '../../../App';

const Header: React.FC = () => {
  const { image } = useContext(TopImageContext);

  return <div className={style.wrapper}>
    <div className={style.logo}>
      <Link to="/">
        <img src={logo} alt="Лого" height="324px" width="221px" />
      </Link>
    </div>
    {image.src ?
      <img className={style.img} src={`${image.local ? ''
        : BASE_URL}${image.src}`} alt="Изображение категории" />
      : <HeaderSlider />}
    <nav className={style.nav}>
      <HeaderNavigation />
    </nav>
  </div>
}

export default Header;