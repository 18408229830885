import { classNames } from '../../../lib/classNames/classNames';
import style from './BusinessCategoryTitle.module.scss'

interface CategoryTitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  className?: string,
  children: React.ReactNode
}

const CategoryTitle: React.FC<CategoryTitleProps> = ({ className, children, ...props }) => {
  return <h1 className={classNames(style.title, {}, [className ?? ''])}
    {...props}>
    {children}
  </h1>
}

export default CategoryTitle;