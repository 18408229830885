import { scrollUnderHeader } from '../../../../lib/scroll/scroll'
import { BASE_URL } from '../../../../lib/services/services'
import { IShop } from '../../../../types/apiDataTypes'
import ShopsSkeleton from '../../../share/ShopsSkeleton/ShopsSkeleton'
import style from './ShopsContainer.module.scss'
import { Link } from 'react-router-dom'

interface ShopsContainerProps {
  shops: IShop[],
  isLoading?: boolean
}

const isPhone = window.matchMedia("(max-width: 768px)").matches;

const ShopsContainer: React.FC<ShopsContainerProps> = ({ shops, isLoading = false }) => {

  return <div className={style.shops}>
    {isLoading ?
      <ShopsSkeleton isMobile={isPhone} />
      : shops.map(shop => {
        return (
          <div className={style.shopItem} key={shop.slug}>
            <Link to={`/cat/shops/${shop.slug}`}
              className={style.itemImgLink}
              onClick={scrollUnderHeader}>
              <img src={`${BASE_URL}${shop.logo}`} className={style.itemImg} alt="Изображение магазина" />
            </Link>
            <div className={style.itemDescription}>
              <h3 className={style.itemTitle}>
                <Link to={`/cat/shops/${shop.slug}`} onClick={scrollUnderHeader}>{shop.name}</Link>
              </h3>
              <div>
                <p className={style.itemText}>{shop.categories[0].name}</p>
                <p className={style.itemText}>{shop.floor} этаж</p>
              </div>
            </div>
          </div>
        )
      })}
  </div>
}

export default ShopsContainer;