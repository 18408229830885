import style from './Shops.module.scss'
import { useContext, useEffect, useState } from 'react';
import topImg from '../../../assets/image/slider/6.webp'
import topImgMobile from '../../../assets/image/slider/tel6.webp'
import ShopsContainer from './ShopsContainer/ShopsContainer';
import { TopImageContext } from '../../../App';
import { useQuery } from 'react-query';
import { getData } from '../../../lib/services/services';
import Container from '../../UI/Container/Container';
import Pagination from '../../share/Pagination/Pagination';
import { setDocumentTitle } from '../../../lib/setDocumentTitle/setDocumentTitle';
import { ApiDataResponseType, IShop } from '../../../types/apiDataTypes';
import CategoryTitle from '../../UI/BusinessCategoryTitle/BusinessCategoryTitle';
import ShopsCategories from './ShopsCategories/ShopsCategories';
import { useSearchParams } from 'react-router-dom';

const isPhone = window.matchMedia("(max-width: 768px)").matches;

const Shops: React.FC = () => {
  const { setImage } = useContext(TopImageContext);
  /* const [category, setCategory] = useState('all'); */

  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get('page')) || 1;
  const category = searchParams.get('category') || "all"

  const { data, isLoading } = useQuery({
    queryKey: ['shops', category, page],
    queryFn: (): Promise<ApiDataResponseType<IShop[]>> => getData(`shop?slug=${category}&page=${page}`, false)
  })

  const changePage = (page: number) => {
    if (page === 1) searchParams.delete('page');
    else searchParams.set('page', String(page));
    setSearchParams(searchParams)
  }

  const toFirstPage = () => {
    changePage(1)
  }

  const changeCategory = (category: string) => {
    if (category == 'all') searchParams.delete('category');
    else searchParams.set('category', category)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    setDocumentTitle('Магазины')
    setImage({ src: isPhone ? topImgMobile : topImg, local: true })
  }, [setImage])

  return <Container>
    <CategoryTitle>Центр Галереи Чижова</CategoryTitle>
    <div className={style.mainWrapper}>
      <ShopsContainer shops={data?.models ?? []} isLoading={isLoading} />
      <ShopsCategories setCategory={changeCategory} toFirstPage={toFirstPage} />
    </div>
    {
      Number(data?.count) > 1 &&
      <Pagination
        activePage={page}
        setActivePage={changePage}
        numberOfPages={Number(data?.count)} />
    }
  </Container>
}

export default Shops;