import style from './Home.module.scss'
import { useEffect, useContext, Suspense, lazy } from 'react';

import CategoryBlockSkeleton from './CategoryBlock/CategoryBlockSkeleton';
import NewsBlockSkeleton from './NewsBlock/NewsBlockSkeleton';
import { setDocumentTitle } from '../../../lib/setDocumentTitle/setDocumentTitle';
import { TopImageContext } from '../../../App';
import Container from '../../UI/Container/Container';
import { useInView } from 'react-intersection-observer';

const CategoryBlock = lazy(() => import('./CategoryBlock/CategoryBlock'))
const NewsBlock = lazy(() => import('./NewsBlock/NewsBlock'))

const Home: React.FC = () => {
  const { setImage } = useContext(TopImageContext);

  useEffect(() => {
    setDocumentTitle('АО Галерея Чижова')
    setImage({ src: '', local: false })
  }, [setImage])

  const { ref: NewsRef, inView: NewsInViews } = useInView({
    rootMargin: '10px',
    triggerOnce: true
  })

  return <Container className={style.wrapper}>
    <section>
      <h1 className={style.title}>Наши направления</h1>
      <Suspense fallback={<CategoryBlockSkeleton />}>
        <CategoryBlock />
      </Suspense>
    </section>

    <section ref={NewsRef}>
      <h1 className={style.title}>Новости</h1>
      <Suspense fallback={<NewsBlockSkeleton />}>
        {
          NewsInViews ? <NewsBlock /> : <NewsBlockSkeleton />
        }
      </Suspense>
    </section>
  </Container>
}

export default Home;