import SkeletonPlaceholder from '../SkeletonPlaceholder/SkeletonPlaceholder'
import style from './ShopsSkeleton.module.scss'

interface ShopsSkeletonProps {
  isMobile?: boolean
}

const ShopsSkeleton: React.FC<ShopsSkeletonProps> = ({ isMobile = false }) => {
  if (isMobile) return new Array(3).fill(null).map((item, index) => <div className={style.shopItem} key={index}>
    <SkeletonPlaceholder className={style.imagePlaceholder} />
    <div className={style.itemDescription}>
      <SkeletonPlaceholder className={style.itemTitlePlaceholder}/>
      <SkeletonPlaceholder className={style.itemTextPlaceholder}/>
    </div>
  </div>)

  return new Array(8).fill(null).map((item, index) => <div className={style.shopItem} key={index}>
  <SkeletonPlaceholder className={style.imagePlaceholder} />
  <div className={style.itemDescription}>
    <SkeletonPlaceholder className={style.itemTitlePlaceholder}/>
    <SkeletonPlaceholder className={style.itemTextPlaceholder}/>
  </div>
</div>)
}

export default ShopsSkeleton;