import SkeletonPlaceholder from '../../../share/SkeletonPlaceholder/SkeletonPlaceholder';
import style from './NewsBlock.module.scss'

const NewsBlockSkeleton: React.FC = () => {
  return <div className={style.grid}>
    {new Array(3).fill(null).map((item, index) => <div className={style.item} key={index}>
      <div className={style.itemLink}>
        <SkeletonPlaceholder className={style.itemImageBlock} />
        <div className={style.itemBody}>
          <SkeletonPlaceholder className={style.placeholderTitle} />
          <SkeletonPlaceholder className={style.placeholderText} />
        </div>
      </div>
    </div>)}
  </div>
}

export default NewsBlockSkeleton;