import Container from '../../UI/Container/Container';
import style from './News.module.scss'
import NewsItemPlaceholder from './NewsItem/NewsItemPlaceholder';

const NewsSkeleton: React.FC = () => {
  return <Container className={style.wrapper}>
    <NewsItemPlaceholder />
    <NewsItemPlaceholder />
    <NewsItemPlaceholder />
  </Container>
}

export default NewsSkeleton;