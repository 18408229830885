import { useQuery } from 'react-query'
import { getData } from '../../../../lib/services/services'
import { ICategory } from '../../../../types/apiDataTypes'
import Search from '../../../share/Search/Search'
import style from './ShopsCategories.module.scss'
import SkeletonPlaceholder from '../../../share/SkeletonPlaceholder/SkeletonPlaceholder'

interface ShopsCategoriesProps {
  toFirstPage: () => void,
  setCategory: (category: string) => void
}

const ShopsCategories: React.FC<ShopsCategoriesProps> = ({ setCategory, toFirstPage }) => {

  const { data: categories, isLoading } = useQuery({
    queryKey: ['shopCategories'],
    queryFn: (): Promise<ICategory[]> => getData(`shop/category`, false)
  })

  const onChangeCategory = (category: string) => {
    setCategory(category);
    toFirstPage()
  }

  return <div className={style.categories}>
    <Search />
    <h2 className={style.searchTitle}>Магазины</h2>
    {isLoading
      ? new Array(16).fill(null).map((el, index) => {
        return <SkeletonPlaceholder key={index} className={style.catPlaceholder} />
      })
      : <>
        <button type='button'
          className={style.catButton}
          onClick={() => onChangeCategory('all')}>
          Все
        </button>
        {categories?.length ? categories.map(({ name, slug }) => {
          return (
            <button
              type='button'
              className={style.catButton}
              key={name}
              onClick={() => {
                onChangeCategory(slug)
              }}>
              {name}
            </button>
          )
        }) : null}
      </>
    }
  </div>
}

export default ShopsCategories;