import Container from '../../UI/Container/Container';
import SkeletonPlaceholder from '../SkeletonPlaceholder/SkeletonPlaceholder';
import style from './MainPageSkeleton.module.scss'

const MainPageSkeleton: React.FC = () => {
  return <Container>
    <SkeletonPlaceholder className={style.titlePlaceholder} />
    <SkeletonPlaceholder className={style.textPlaceholder} />
    <SkeletonPlaceholder className={style.textPlaceholder} />
    <SkeletonPlaceholder className={style.textPlaceholder} />
  </Container>
}

export default MainPageSkeleton;