import style from './Footer.module.scss'
import { Link } from 'react-router-dom';
import whatsApp from '../../../assets/image/society/1.png'
import telegram from '../../../assets/image/society/2.png'
import viber from '../../../assets/image/society/3.png'
import android from '../../../assets/image/society/android.png'
import apk from '../../../assets/files/AppGalleryChizhov.apk'

const year = new Date().getFullYear();

const Footer: React.FC = () => {
  return (
    <footer className={style.wrapper}>
      <div className={style.content}>
        <div className={style.items}>
          <div className={style.time}>
            <strong>Служба поддержки</strong><br />
            Время работы<br />
            <strong>с 9.00 до 21.00,</strong><br />
            без перерыва и выходных
          </div>
          <div className={style.contact}>
            <a className={style.phone} href="tel:+7 473 261-99-99">+7 473 261-99-99</a>
            <Link to="contacts" className={style.feedback}>Обратная связь</Link>
            <div className={style.social}>
              <a className={style.socialLink} href="https://wa.me/79100419999"><img src={whatsApp} alt="whatsapp" /></a>
              <a className={style.socialLink} href="https://t.me/Chizhov_Gallery_Association"><img src={telegram} alt="telegram" /></a>
              <a className={style.socialLink} href="https://viber.click/79100419999"><img src={viber} alt="viber" /></a>
              <a className={style.socialLink} href={apk} download><img src={android} alt="android.apk" /></a>
            </div>
          </div>
        </div>
        <p className={style.bottom}>
          © 1997 - {year} Ассоциация «Галерея Чижова»
        </p>
      </div>
    </footer>
  )
}

export default Footer;