import style from './Pagination.module.scss'
import { useEffect, useState } from 'react';
import { scrollUnderHeader } from '../../../lib/scroll/scroll';
import { classNames } from '../../../lib/classNames/classNames';
import { initPagination } from '../../../lib/initPagination/initPagination';

interface PaginationProps {
  activePage: number,
  setActivePage: (page: number) => void,
  numberOfPages: number
}

const isPhone = window.matchMedia("(max-width: 768px)").matches;

const Pagination: React.FC<PaginationProps> = ({ activePage, setActivePage, numberOfPages }) => {
  const [pagination, setPagination] = useState<number[]>([]);

  const setPreviousPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1)
    }
  }

  const setNextPage = () => {
    if (activePage < numberOfPages) {
      setActivePage(activePage + 1)
    }
  }

  const setPage = (page: number) => {
    setActivePage(page)
  }

  useEffect(() => {
    setPagination(initPagination(numberOfPages, activePage, isPhone));
  }, [numberOfPages, activePage])

  useEffect(() => {
    scrollUnderHeader();
  }, [activePage])

  return (
    <ul className={style.pagination}>
      <button className={classNames(style.item, { [style.disabled]: activePage === 1 })}
        onClick={setPreviousPage}>
        <span>«</span>
      </button>

      {pagination.map(item => {
        return (
          <button key={item}
            className={classNames(style.item, { [style.active]: item === activePage })}
            onClick={() => setPage(item)}>
            <span>{item}</span>
          </button>
        )
      })}

      <button className={classNames(style.item, { [style.disabled]: activePage === numberOfPages })}
        onClick={setNextPage}>
        <span>»</span>
      </button>
    </ul>
  )
}

export default Pagination;