import style from './NotFound.module.scss'
import { Link } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { TopImageContext } from '../../../App';
import { setDocumentTitle } from '../../../lib/setDocumentTitle/setDocumentTitle';
import Container from '../../UI/Container/Container';

const NotFound: React.FC = () => {
  const { setImage } = useContext(TopImageContext);

  useEffect(() => {
    setDocumentTitle('Страница не найдена')
    setImage({ src: "", local: true });
  }, [setImage])

  return <Container className={style.wrapper}>
    <h1 className={style.title}>Страница не найдена</h1>
    <p className={style.text}>Запрашиваемая страница не существует</p>
    <p className={style.text}>Возможно, она была удалена или в запросе был указан неверный адрес страницы</p>
    <p className={style.text}>
      Попробуйте вернуться
      <Link to="/">
        <strong className={style.boldText}>на главную</strong>
      </Link> или связаться с нами по телефону
      <a href="tel:+74732619999"><strong className={style.boldText}>
        +7(473)261-99-99</strong>
      </a>
    </p>
  </Container>
}

export default NotFound;